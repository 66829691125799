import * as React from 'react';


export default function Document() {

    return (
        <div>
            <div className="max-w-2xl mx-auto py-40">
                <article>
                    <h1 className="text-7xl mb-6">Privacy Policy</h1> <br />
                    <h2 className="text-4xl mb-8">Who am I</h2> 
                    <p className="mb-8">My Name is Oliver Hovey, Operating under the brand name Salient Applications. You can contact me via ollie@salientapplications.uk</p>
                    
                    
                    <h2 className="text-4xl">What personal data do I collect and why</h2>

                    <h2 className="text-2xl mb-8 mt-8">Contact Forms</h2>
                    
                    <p className="mb-8">
                        When a user submits their information via a contact form, this data is stored on my Gmail account. This data includes you name, email address and - if included – your telephone number. This data is stored for the purpose of maintaining a point of contact should I (as I expect) need to reach out to you after you have submitted your data.
                        In the process of sending sending the information to my email address, the same data is processed by two services: EmailJS and Mailgun. A record of contact information is kept by each under the terms they describe in their respective privacy policies. Each stores ‘logs’ of message data for up to 30 days.
                    </p>

                    <h2 className="text-2xl mb-8">Cookies</h2>
                    
                    <p className="mb-8">
                        Cookies are small files that websites send to your device that the sites then use to monitor you and remember certain information about you — like what's in your shopping cart on an e-commerce site, or your login information.
                        Salient Applications may use cookies to determine whether a user has authorized access certain areas with the site. This cookie will not be present for guest users.
                        
                    </p>

                    <h2 className="text-4xl mb-8">Personal rights concerning proccing of data</h2>
                    
                    <p className="mb-8">
                        
                        Any persons wishing for personal data to be removed from my possession may contact me at support@salientapplications.uk and I will remove personal information from all channels where it is stored and/or referenced. 
                        At no point in the use of this site is any user under any statutory or contractual obligation to provide their personal data.
                    </p>
                </article>
            </div>
        </div>
    )
}